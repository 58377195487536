<template>
  <div>
    <!-- dict: download45F0B8ED59F89D341C1E53771729B013.swf -->
    <!-- pic190B44C25CDA59F0938AE867C9C20A46 -->
    <img class="rounded w-680 h-490" src="">

    <p>
      You LEGO Universe fans go nuts for screenshots, so we're delivering more-- starting now!
    </p>
    <p>
      Keep your browser locked on the LEGO Universe News Network for more looks into the first <b>M</b>assively <b>M</b>ultiplayer <b>O</b>nline <b>G</b>ame (MMOG) for LEGO fans!
    </p>
  </div>
</template>
